(function() {
	var KRP0005 = {
		init: function(){
            var self = this;
            self.listData = null;
            self.setting();
            self.bindEvents();

            var cookieValue = lgkorUI.getCookie(lgkorUI.RECENT_PROD_COOKIE_NAME);
            if(cookieValue) {
                self.requestData(false);
            } else {
                //최근본 제품이 없으면 최근본 제품 버튼을 숨긴다
                self.$KRP0005.find('.floating-linker.recently').hide();
            }
		},

        setting: function() {
            var self = this;

            self.$floatingWrap = $('.btn-floating-wrap');
            self.$KRP0005 = $('.KRP0005.floating-menu');
            self.moreButton = self.$KRP0005.find('.more-plus-linker a');

            self.$popup = $('#KRP0032:eq(0)');
            self.$list = self.$popup.find('div.lately-list ul');
        },
        bindEvents: function() {
            var self = this;
            
            //BTOCSITE-43362 챗봇에서 인입시 공통 플로팅 비노출 요청 - 이벤트/멤버십
            if (location.search.includes("chatbot=y")  ) {
	            	$("#chatbotBtn2").hide();
	                $("#chatbotBtn1").hide();
	                lgkorUI.setStorage('chatbotFloatingOpen', 'N');
            }
            // BTOCISTE-27076 공통 > 챗봇 아이콘 변경 및 활성화
            // BTOCSITE-46981 공통 > 챗봇 아이콘 변경 요청
            else if (location.pathname.includes("/search/") || location.pathname.includes("/support") || "PDP" === $("#chatbotBtn1").data("page-type") || location.pathname == "/") {
                if(Object.keys(lgkorUI.getStorage('chatbotFloatingOpen')).length == 0) {
                    $("#chatbotBtn2").hide();
                    $("#chatbotBtn1").show();
                    //lgkorUI.setStorage('chatbotFloatingOpen', 'Y');  //setTimeout 함수로 이동
                } 
            }   

            self.moreButton.on('click',function(e){
                e.preventDefault();
                var isOpen = self.$floatingWrap.hasClass('open');
                if(isOpen) {
                    if(self.$popup.hasClass('open')) {
                        //만약 최근본 제품 팝업이 열려 있으면 닫는다/
                        self.closePopup();
                    }
                    self.$floatingWrap.removeClass('open');
                    //닫기
                    self.moreButton.attr('aria-expanded',false);
                    self.moreButton.find('span').text('서비스 메뉴 열기'); //BTOCSITE-5223 : 플로팅 버튼 GA 수정 2021-09-14
                } else {
                    self.$floatingWrap.addClass('open');
                    //열기
                    self.moreButton.attr('aria-expanded',true);
                    self.moreButton.find('span').text('서비스 메뉴 닫기'); //BTOCSITE-5223 : 플로팅 버튼 GA 수정 2021-09-14
                }
            });

            self.$KRP0005.on('click','div.floating-linker > a',function(e){

                e.preventDefault();
                var $div = $(this).parents('div.floating-linker');

                if ($div.hasClass('faq')){
                    window.open('/support/usage-guide-faq','_blank');
                    return;
                }

                if($div.hasClass('chat')) {
                    //상담쳇
                } else if($div.hasClass('recently')) {
                    //최근본 제품
                    if(!self.listData) {
                        self.requestData(true);
                    } else {
                        self.openPopup();
                    }
                } else {
                    //
                    e.preventDefault();
                    var href = $(this).attr('href');
                    if(href && href.replace("#", "").length > 0) {
                        location.href = href;
                    }
                }
            });

            self.$popup.on('click','.ui_modal_close',function(e){
                e.preventDefault();
                self.closePopup();
            });

            // BTOCSITE-12128 메인 성능개선
            $(window).on('floatingTopHide', function(e){
                if(self.$floatingWrap.hasClass('scroll')) self.$floatingWrap.removeClass('scroll');
            });

            $(window).on('floatingTopShow', function(e){
                if(!self.$floatingWrap.hasClass('scroll')) self.$floatingWrap.addClass('scroll');
            });

            // BTOCSITE-27076 공통 > 챗봇 아이콘 변경 및 활성화
            $('.btn-chatbot').on('click',function(e){
                if (isApp()) {
                    e.preventDefault();

                    var tmpUrl = $('.btn-chatbot').attr('href');
                    var url = "";

                    if(tmpUrl.indexOf("?")>-1){
                        url = tmpUrl + "&channel=lg_app&inflow=mobileapp";
                    }else{
                        url = tmpUrl + "?channel=lg_app&inflow=mobileapp";
                    }
                    var pinUrl = "https://chat-bridge.lgechat.co.kr/pins/codes";
                    
                    if (location.hostname === 'localhost' || location.hostname.indexOf("wwwdev50") > -1){
                        url = "https://chat-st.lgechat.co.kr?channel=lg_app&inflow=mobileapp";
                        pinUrl = "https://chat-bridge-st.lgechat.co.kr/pins/codes";
                    } else if (location.hostname.indexOf('wwwstg') > -1) {
                        url = "https://chat-qa.lgechat.co.kr?channel=lg_app&inflow=mobileapp";
                        pinUrl = "https://chat-bridge-st.lgechat.co.kr/pins/codes";
                    } else {
                        if (url.indexOf("#none") > -1) {
                            url = $('.btn-chatbot').data('url') + "?channel=lg_app&inflow=mobileapp";
                        }
                    }
                    
                    if (vcui.detect.isIOS) {
                        var jsonString = JSON.stringify({
                            command: "openChatbot",
                            url: url,
                            pinUrl: pinUrl
                        });
                        webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                    } else if (vcui.detect.isAndroid) {
                        void android.openChatbot(url, pinUrl)
                    }
                }
            });
        },

        //최근본 제품 처리

        //리스트 열기
        openPopup: function() {
            var self = this;
            self.resetImage();
            self.$popup.show();
            self.$popup.removeClass('close');
            self.$popup.addClass('open');

            self.ignoreOverflowForce = $('body').hasClass('ignore-overflow-hidden');
            if(!self.ignoreOverflowForce && vcui.detect.isMobile){
                $('html, body').css({
                    overflow:"hidden"
                });
            }
        },

        //리스트 닫기
        closePopup: function() {
            var self = this;
            self.$popup.addClass('close');
            self.$popup.removeClass('open');
            self.$popup.hide();

            self.ignoreOverflowForce = $('body').hasClass('ignore-overflow-hidden');
            if(!self.ignoreOverflowForce && vcui.detect.isMobile) {

                $('html, body').css({
                    overflow:"visible"
                });

            }
        },

        resetImage: function() {
            var self = this;
            var $img = self.$popup.find('img[data-temp-src]');
            $img.each(function(idx,obj){
                if(!obj.src.length || obj.src.length == "") {
                    obj.src = obj.dataset.tempSrc;
                }
            });
        },

        //최근본 제품 리스트 가져오기
        requestData: function(openPopup) {
			var self = this;
			var ajaxUrl = self.$popup.attr('data-list-url');

            lgkorUI.requestAjaxDataPost(ajaxUrl, null, function(result) {
				var data = result.data;
				var arr = data instanceof Array ? data : [];
                self.listData = arr;
				self.$list.empty();

                var popuplistItemTemplate = '<li{{#if disabledReason}} class="disabled"{{/if}}>' +
                    '<div class="img"><a href="{{modelUrlPath}}"><img data-temp-src="{{smallImageAddr}}" alt="{{imageAltText}}"></a></div>' +
                    '<dl><a href="{{modelUrlPath}}"><dt>{{#raw modelDisplayName}}</dt><dd>{{#if disabledReason}}{{disabledReason}}{{#else}}{{#if price}}{{price}}원{{/if}}{{/if}}</dd></a></dl>' +
                '</li>'

				arr.forEach(function(item, index) {
                    var price = item.obsSellingPrice ? (item.obsSellingPrice > 0 ? item.obsSellingPrice : null) : null;
                    item.price = price ? vcui.number.addComma(price) : null;
                    item.disabledReason = item.disabledReason && item.disabledReason.length > 0 ? item.disabledReason : null;
					self.$list.append(vcui.template(popuplistItemTemplate, item));
                });

                self.checkNoData();

                if(openPopup) {
                    self.openPopup();
                }
			}, null, true);
        },

        //최근본 제품 noData 체크
        checkNoData: function() {
            var self = this;
            if(self.$list.find('li').length > 0) {
                self.$KRP0005.find('.floating-linker.recently').show();
            } else {
                self.$KRP0005.find('.floating-linker.recently').hide();
            }
        }

	};

    // BTOCSITE-2838 : 매니저 정보로 이동 s
    var managerInfoLink= 'managerInfoLink';
    $('.btn-manager-info').on('click', function(e){
        e.preventDefault();
        lgkorUI.setStorage(managerInfoLink, true);
        location.href='/my-page/care-solution-contract-status';
    });
    // BTOCSITE-2838 :매니저 정보로 이동 e

    function funcReady() {
        // BTOCSITE-27 :: 플로팅 바 swipe 대응
        var isSwipe = !!$('#sw_con').length;

        if (isSwipe){
            // 20210810 BTOCSITE-1814
            if($('#floatBox').length == 0) $('#sw_con').after('<div id="floatBox"></div>');

            var floatingWrap = $('#sw_con .floating-wrap').remove();
            var btnFloatingWrap = $('#sw_con .btn-floating-wrap').remove();

            if($('#floatBox').find('.floating-wrap').length < 1) {
                var domInsertCheck = false;
                $('#sw_con .swiper-slide').one('DOMNodeInserted', function(e) {
                    if(!domInsertCheck) {
                        $('#floatBox').append(btnFloatingWrap);
                        $('#floatBox').append(floatingWrap);

                        // preload 대응 현재 슬라이드가 고객지원일때는 숨김처리
                        // BTOCSITE-39262 고객지원 영역에 신규 하단탭바 반영
                        // BTOCSITE-39930 고객지원 메인 안드로이드 오류 수정
                        if ($('.swiper-slide-active').data().hash == 'support'){
                            $(floatingWrap).show();
                            $(btnFloatingWrap).show();

                            $('body > .btn-floating-wrap').remove();
                            $('body > .floating-wrap').remove();
                        }
                        $('.back-to-top button').off('click').on('click', function (e) {
                            e.preventDefault();
                            $(window).trigger('floatingTop');
                            $('html, body').stop().animate({
                                scrollTop: 0
                            }, 400);
                        });

                        KRP0005.init();
                        $(document).trigger('appInit');
                        domInsertCheck = true;
                    }
                });
            }
        } else {
            // 스와이프 아닌 페이지
            KRP0005.init();
        }

        // BTOCSITE-27 :: 플로팅 바 swipe 대응
    }

    // BTOCSITE-26022, PDP 컴포넌트 초기화 함수 추가, type: PROD or CONTENTS
    function SAVE_COMPONENT_PDP_DOM_MNG() {
        if (typeof __PDP_DOM_MNG__ === 'undefined') return;
        __PDP_DOM_MNG__.add({
            type: 'HELP', name: 'KRP0005', isFirstLoad: false, fn: function () {
                if(!document.querySelector('.KRP0005')) return false;
                funcReady();
            }
        });
    }

    // S : BTOCSITE-27076 공통 > 챗봇 아이콘 변경 및 활성화
    $(window).on('activeChatbot',function(){
        setTimeout(function(){
            //console.log('챗봇호출');
            $('.btn-chatbot-wrap .is-msg').addClass('active');
            
            //특정 페이지들(홈,검색,PDP)에서 세션별 한번만 노출되는 챗봇 문구를 위한 세션 세팅 값 :   
            //해당 위치로 이동한 이유
            //해당 문구는 특정페이지 접속 후 5초 후에 노출되는 메세지로서 
            //기존에는 특정페이지 접속만해도 노출했다고, 세션에 기록 됨
            //특정페이지에서 5초안에 다른 페이지로 접속한다면 ex) 홈 -> 고객지원
            //챗봇 문구가 노출되지 않음
            lgkorUI.setStorage('chatbotFloatingOpen', 'Y');      
            
            $("body").on("mousewheel touchmove", function (e) {
                $('.btn-chatbot-wrap .is-msg').removeClass('active');
            });
            setTimeout(function(){
                $('.btn-chatbot-wrap .is-msg').removeClass('active');
            },5000)
        },10000);
    })
    //홈메인 챗봇 활성화 예외처리
    if(location.pathname != '/'){
        $(window).trigger('activeChatbot');
    }
    // E : BTOCSITE-27076 공통 > 챗봇 아이콘 변경 및 활성화

    SAVE_COMPONENT_PDP_DOM_MNG();

    $(document).ready(function(){
        SAVE_COMPONENT_PDP_DOM_MNG()

        if(!document.querySelector('.KRP0005')) return false;
        funcReady();

        //BTOCSITE-57306 APP > B2B > 하단 고정 버튼 있을때
        var locationHref =  location.href;
        var isB2B = locationHref.indexOf('.kr/business') > -1 ? true : false;
        if(isB2B && isApp()){
            //APP > B2B > 하단 고정 버튼이 있어 하단바 비활성화 및 플로팅 배너 위치 보정 리스트
            var btnShowList = lgkorUI.B2BBarHideList;
            var isBtnShow = false;
            for(var i=0; i<btnShowList.length; i++){
                if(locationHref.indexOf(btnShowList[i]) > -1){
                    isBtnShow = true;
                }
            }
            if(isBtnShow){
               $('.btn-floating-wrap').addClass('bottom-btn');
               $('.floating-wrap').addClass('bottom-btn');
            }
        }
    });
})();